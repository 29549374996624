package tta.destinigo.talktoastro.feature_expert_details.expert_detail_page.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Chat
import androidx.compose.material.icons.filled.Call
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_starter.CallHostPage
import tta.destinigo.talktoastro.feature_expert_details.expert_detail_page.ExpertDetailsPageComponent
import tta.destinigo.talktoastro.resources.Res
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_duration_dailog.ChatConsultationStarter
import tta.destinigo.talktoastro.resources.expert_details_ic_filled_star
import tta.destinigo.talktoastro.shared.data.CommonExpertInfo
import tta.destinigo.talktoastro.shared.data.RechargeData
import tta.destinigo.talktoastro.shared.data.RequestConsultant
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.getIsLogin
import tta.destinigo.talktoastro.shared.local.openPlayStore
import tta.destinigo.talktoastro.shared.persentation.ExpertPriceRow
import tta.destinigo.talktoastro.shared.persentation.SampleDialog
import tta.destinigo.talktoastro.shared.platform.showToast
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.Scale.CORNER_SMALL
import tta.destinigo.talktoastro.shared.theme.call_and_chat_button_offline_color
import tta.destinigo.talktoastro.shared.theme.initializeCurrency
import tta.destinigo.talktoastro.shared.theme.primaryColor
import tta.destinigo.talktoastro.shared.theme.star_rating_color
import tta.destinigo.talktoastro.shared.theme.user_app_play_store_url
import kotlin.math.round


@Composable
fun RelatedConsultantListItemUI(
    component: ExpertDetailsPageComponent,
    onItemClick: (RequestConsultant) -> Unit,
    onLoginClick: () -> Unit,
    onChatConsultation: (RequestConsultant, String, String) -> Unit,
    onCallConsultation: (RequestConsultant) -> Unit,
    onRequiredRecharge: (RechargeData?) -> Unit,
    onVoIPCallConsultationStart: (String, CommonExpertInfo)->Unit
) {
    val topConsultantData by component.relatedExpertState.collectAsState()
    var isCallClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }

    if (topConsultantData.isNullOrEmpty()) return

    Column(
        modifier = Modifier.padding(horizontal = Scale.HORIZONTAL_PADDING).fillMaxWidth()
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxWidth()
        ) {
            Column(
                modifier = Modifier.weight(1f)  // Ensures the Column takes up the remaining horizontal space
                //.padding(start = Scale.HORIZONTAL_PADDING, end = Scale.HORIZONTAL_PADDING) // Add padding if needed
            ) {
                Text(
                    text = "Related Expert",
                    maxLines = 1,
                    style = MaterialTheme.typography.titleMedium,
                    fontWeight = FontWeight.Bold,
                    fontSize = 15.sp,
                    modifier = Modifier.padding(top = Scale.VERTICAL_PADDING)
                )
                Text(
                    text = "Book Consultation Sessions with Leading Experts",
                    style = MaterialTheme.typography.bodyMedium
                )
            }
        }
        Spacer(modifier = Modifier.height(10.dp))

        LazyRow(
            modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            items(topConsultantData!!.size) { consultantID ->
                val consultant = topConsultantData!![consultantID]
                ConsultantListItem(
                    consultant = consultant,
                    onItemClick = onItemClick,
                    onCallClick = { callConsultant ->
                        isCallClickSelected = callConsultant
                    },
                    onChatClick = { chatConsultant, duration, category ->
                        onChatConsultation.invoke(chatConsultant, duration, category)
                    },
                    isFreeCallApplicable = component.isFreeConsultationRelatedExpert == 1,
                    onRequiredRecharge = { data ->
                        onRequiredRecharge.invoke(data)
                    },
                    onLoginClick = {
                        onLoginClick.invoke()
                    },
                    component = component
                )
            }

        }
    }

    if (isCallClickSelected != null) {
        Logger.d("ifCallClicked")
        CallHostPage(
            callDetails = isCallClickSelected!!,
            onLoginClicked = {
                onLoginClick.invoke()
            },
            onDismiss = {
                isCallClickSelected = null
            },
            onStartCallConsultation = { onCallConsultation.invoke(isCallClickSelected!!) },
            context = component,
            onNavigateRechargeCheckOut = { rechargeData ->
                onRequiredRecharge.invoke(rechargeData)
            },
            onVoIpCallStart = {duration, commonExpertInfo ->
                onVoIPCallConsultationStart.invoke(duration, commonExpertInfo)
            },
            onNavigateCallWaitingPage = {  CommonExpertInfo,onClose ->
                //onNavigateCallWaitingPage.invoke(CommonExpertInfo,onClose)
            }
            )
    }

}


@Composable
fun ConsultantListItem(
    component: ExpertDetailsPageComponent,
    consultant: RequestConsultant,
    onItemClick: (RequestConsultant) -> Unit,
    onCallClick: (RequestConsultant) -> Unit,
    onChatClick: (RequestConsultant, String, String) -> Unit,
    onLoginClick: () -> Unit,
    isFreeCallApplicable: Boolean,
    onRequiredRecharge: (RechargeData?) -> Unit
) {

    var isChatClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }

    Column(
        modifier = Modifier.padding(4.dp).width(160.dp).wrapContentHeight()
    ) {
        Card(
            colors = CardDefaults.cardColors(Color.White),
            modifier = Modifier.clickable { onItemClick(consultant) }.height(220.dp),
           // modifier = Modifier.clickable { onItemClick(consultant) }.height(250.dp),
            shape = RoundedCornerShape(CORNER_SMALL),
            elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
        ) {
            Column(
                modifier = Modifier.padding(8.dp),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally // Center the content horizontally
            ) {
                RatingRow(consultant.consultant_rating!!)

                Spacer(modifier = Modifier.height(5.dp))

                ProfileImage(consultant.profile_image_url)

                Spacer(modifier = Modifier.height(5.dp))

                Text(
                    text = "${consultant.year_of_experience}+ Years",
                    style = TextStyle(color = Color.Black),
                    fontSize = 12.sp,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.fillMaxWidth()
                )

                Text(
                    text = consultant.full_name ?: "",
                    maxLines = 1,
                    style = MaterialTheme.typography.titleMedium,
                    modifier = Modifier.fillMaxWidth(),
                    textAlign = TextAlign.Center
                )

                Text(
                    text = consultant.skills ?: "",
                    maxLines = 2,
                    modifier = Modifier.fillMaxWidth(),
                    style = MaterialTheme.typography.bodySmall,
                    overflow = TextOverflow.Ellipsis,
                    textAlign = TextAlign.Center
                )

                Box(
                    modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center
                ) {

//                    ExpertPriceRow(
//                        isFreeCallApplicable = component.isFreeConsultation,
//                        //isFreeCallApplicable = if(consultant.isFreeCall?.equals(1) == true) true else false,
//                        callPerMinutePrice = consultant.callPerMinutePrice.toString(),
//                        expertPrice = consultant.expert_price ?: 0,
//                        winywayPrice = consultant.winyway_price ?: 0,
//                        flashPrice = consultant.flashPerMinutePrice,
//                        horizontalArrangement = Arrangement.Start
//                    )

                    Column {
                        ExpertPriceRow(
                            isFreeCallApplicable = component.isFreeConsultationRelatedExpert == 1,
                            callPerMinutePrice = consultant.callPerMinutePrice.toString(),
                            expertPrice = consultant.expert_price ?: 0,
                            winywayPrice = consultant.winyway_price ?: 0,
                            flashPrice = consultant.flashPerMinutePrice,
                            horizontalArrangement = Arrangement.Center
                        )
                        //CallPrice(consultant, isFreeCallApplicable)
                    }


                }
            }
        }
        val openDialog = remember { mutableStateOf(false) }
        if (openDialog.value) {
           openDialog(openDialog)
        }

//        // Call Button
//        CallConsultationButton(
//            modifier = Modifier.weight(1f).height(36.dp),
//            chatStatus = consultant.chatButtonStatus,
//            callStatus = consultant.callStatus,
//            onClick = {
//                onCallClick.invoke(consultant)
//            },
//            isTextVisible = false
//        )
//
//        Spacer(modifier = Modifier.weight(0.1f))
//
//        // Chat Button
//        ChatConsultationButton(
//            modifier = Modifier
//                .weight(1f)
//                .height(36.dp),
//            chatStatus = consultant.chatButtonStatus,
//            callStatus = consultant.callStatus,
//            onClick = {
//                onChatClick.invoke(consultant,"","")
//            },
//            isTextVisible = false
//
//        )
        ActionButtons(consultant,
            onCallClick = { onCallClick.invoke(consultant) },
            onChatClick = { consultant ->
                isChatClickSelected = consultant
            })
    }

    if (isChatClickSelected != null) {
        Logger.d("ifChatClicked")
        ChatConsultationStarter(
            chatDetails = isChatClickSelected!!,
            onLoginClicked = {
                onLoginClick.invoke()
            },
            onDismiss = {
                isChatClickSelected = null
            },
            onStartChatConsultation = { duration, category,chatType ->
                onChatClick.invoke(isChatClickSelected!!, duration, category)
            },
            context = component,
            onRechargeAgain = { rechargeData: RechargeData? ->
                onRequiredRecharge.invoke(rechargeData)
            },
            lowBalanceRecharge = {
                component.onLowBalance()
            }
            )
    }
}

@Composable
fun openDialog(sheet: MutableState<Boolean>) {
    if (sheet.value) {
        SampleDialog(onDismissRequest = { sheet.value = false }, onPlayStore = {
            sheet.value = false
            openPlayStore(user_app_play_store_url)
            //showToast("Play Store")
        }
//            onAppStore = {
//                showToast("App Store")
//            }
        )
    }
}

@Composable
fun RatingRow(rating: Double) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            painter = painterResource(Res.drawable.expert_details_ic_filled_star),
            // painter = Icon(Icons.Default.Call),
            contentDescription = null, tint = star_rating_color, modifier = Modifier.size(14.dp)
        )
        ConsultantRating(rating)
    }
}

@Composable
fun ProfileImage(profileImageUrl: String?) {
    Box(
        modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier.size(85.dp).border(
                width = 2.dp, color = Color.Gray.copy(alpha = 0.3f), shape = CircleShape
            ).clip(CircleShape).background(Color.White).padding(1.dp).clip(CircleShape)
        ) {
            AsyncImage(
                model = profileImageUrl,
                modifier = Modifier.size(85.dp).clip(CircleShape),
                contentScale = ContentScale.Crop,
                contentDescription = null,
            )
        }
    }
}


@Composable
fun CallPrice(consultant: RequestConsultant, isFreeCallApplicable: Boolean) {
    val callPerMinutePrice = consultant.callPerMinutePrice.toString()
    val isExpertFreeCall = consultant.isFreeCall == 1
    val flashPrice = consultant.expertFlashPrice?.let { consultant.winywayFlashPrice?.plus(it) }
    val isFreeCallApplicableCalculated =
        if (!getIsLogin()) isExpertFreeCall else isExpertFreeCall && isFreeCallApplicable

    if (isFreeCallApplicableCalculated) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center) {
            Box(
                modifier = Modifier
                    //.align(Alignment.TopEnd) // Position the tag at the top-right corner
                    .padding(4.dp)
                    .background(primaryColor.copy(alpha = 0.8f), RoundedCornerShape(Scale.CORNER_EXTRA_MIN))
                    .padding(horizontal = 6.dp, vertical = 2.dp),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = "New User",
                    style = MaterialTheme.typography.labelSmall,
                    color = Color.White
                )
            }
//            Text(
//                text = "NEW USER",
//                fontSize = 14.sp,
//                textAlign = TextAlign.Center,
//                color = Color.Black,
//                modifier = Modifier.padding(horizontal = 1.dp)
//            )
            Text(
                text = "${initializeCurrency()} $callPerMinutePrice/Min",
                color = Color.Black,
                fontSize = 14.sp,
                modifier = Modifier.alpha(0.6f),
                style = TextStyle(
                    fontStyle = FontStyle.Italic,
                    textDecoration = TextDecoration.LineThrough
                )
            )
        }
    } else {
        if (flashPrice != null && flashPrice.toInt() != 0) {
            Box(
                modifier = Modifier.padding(horizontal = 2.dp, vertical = 1.dp),
                contentAlignment = Alignment.Center
            ) {
                Row(
                    // modifier = Modifier.padding(horizontal = 2.dp, vertical = 1.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = "${initializeCurrency()} ${flashPrice}/Min",
                        fontSize = 14.sp,
                        textAlign = TextAlign.Center,
                        color = Color.Black,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.padding(horizontal = 1.dp)
                    )
                    Spacer(modifier = Modifier.width(1.dp))
                    Text(
                        text = "${initializeCurrency()} $callPerMinutePrice/Min",
                        fontSize = 12.sp,
                        textAlign = TextAlign.Center,
                        color = Color.Gray,
                        textDecoration = TextDecoration.LineThrough,
                        modifier = Modifier.padding(horizontal = 1.dp)
                    )
                }
            }
        } else {
            Box(
                modifier = Modifier.padding(horizontal = 2.dp, vertical = 1.dp),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = "${initializeCurrency()} $callPerMinutePrice /Min",
                    fontSize = 14.sp,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold,
                    color = Color.Black,
                    maxLines = 1,
                    modifier = Modifier.padding(horizontal = 2.dp)
                )
            }
        }
    }
}

@Composable
fun ActionButtons(
    consultant: RequestConsultant, onCallClick: () -> Unit, onChatClick: (RequestConsultant) -> Unit
) {
    Row(
        modifier = Modifier.fillMaxWidth().height(50.dp),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        // Call Button
        Button(
            onClick = {
                if (((consultant.consultantCallAvailability()!!) == "Online")) onCallClick() else showToast(
                    "Expert is busy or offline"
                )
//                if (consultant.callStatus.equals("Online", true)) {
//                    onCallClick()
//                } else {
//                    // Show a toast message or handle the offline scenario
//                    showToast("Expert is busy or offline")
//                }
            },
            enabled = ((consultant.consultantCallAvailability()!!) == "Online"),
            colors = ButtonDefaults.buttonColors(
                containerColor = if ((consultant.consultantCallAvailability()!!) == "Online") {
                    Color(0xFF25d366)
                } else if ((consultant.consultantCallAvailability()!!) == "Busy") {
                    Color(0xFFEF3636)
                } else {
                    call_and_chat_button_offline_color
                }
            ),
            shape = RoundedCornerShape(CORNER_SMALL),
            modifier = Modifier.weight(1f).height(36.dp)
        ) {
            Icon(
                imageVector = Icons.Filled.Call,
                contentDescription = "Consult Now",
                tint = Color.White,
                modifier = Modifier.padding(3.dp).size(Scale.GLOBAL_ICON_SIZE)
            )
        }

        Spacer(modifier = Modifier.weight(0.1f))

        // Chat Button
        Button(
            onClick = {
                if (((consultant.consultantChatAvailability()!!) == "Online")) onChatClick.invoke(
                    consultant
                ) else showToast(
                    "Expert is busy or offline"
                )
//                if (consultant.chatButtonStatus.equals("Online", true)) {
//                    onChatClick()
//                } else {
//                    // Show a toast message or handle the offline scenario
//                    showToast("Expert is not available for chat")
//                }
            },
            enabled = ((consultant.consultantChatAvailability()!!) == "Online"),
            colors = ButtonDefaults.buttonColors(
                containerColor = if ((consultant.consultantChatAvailability()!!) == "Online") {
                    Color(0xFF1B98F0)
                } else if ((consultant.consultantCallAvailability()!!) == "Busy") {
                    Color(0xFFEF3636)
                } else {
                    call_and_chat_button_offline_color
                }
            ),
            shape = RoundedCornerShape(CORNER_SMALL),
            modifier = Modifier.weight(1f).height(36.dp)
        ) {
            Icon(
                imageVector = Icons.AutoMirrored.Filled.Chat,
                contentDescription = "Chat Now",
                tint = Color.White,
                modifier = Modifier.padding(3.dp).size(Scale.GLOBAL_ICON_SIZE)
            )
        }
    }
}


@Composable
fun ConsultantRating(rating: Double?) {
    val roundedRating = rating?.coerceIn(0.0, 5.0)?.let { round(it * 10) / 10 } ?: 5.0
    val ratingText = "$roundedRating"
    Text(
        text = ratingText,
        fontSize = 12.sp,
        style = TextStyle(fontWeight = FontWeight.Bold),
        textAlign = TextAlign.Center,
        color = Color.Black,
        modifier = Modifier.padding(horizontal = 4.dp)
    )
}