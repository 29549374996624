package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.graphics.Color
import tta.destinigo.talktoastro.feature_expert_details.data.remote.ExpertDetailsApiService
import tta.destinigo.talktoastro.feature_expert_details.data.remote.request.RequestConsultInfo
import tta.destinigo.talktoastro.feature_expert_details.expert_detail_page.ExpertDetailsPageComponent
import tta.destinigo.talktoastro.feature_expert_details.expert_detail_success_page.ExpertDetailsSuccess
import tta.destinigo.talktoastro.feature_expert_details.expert_detail_page.expert_details_state_info.SubmitExpertReviewState
import tta.destinigo.talktoastro.shared.persentation.HomePageLoadingProgressDialog
import tta.destinigo.talktoastro.shared.platform.showToast
import tta.destinigo.talktoastro.shared.remote.Resources

@Composable
fun ExpertDetails(component: ExpertDetailsPageComponent) {

    val state by component.state.collectAsState()
    val submitExpertReviewState by component.submitReviewState.collectAsState()
    val relatedExpertState by component.relatedExpertState.collectAsState()
    val expertDetailsInfoState =
        remember { mutableStateOf<Resources<RequestConsultInfo>>(Resources.Loading()) }

    // Fetch Expert Details on Component Initialization
    LaunchedEffect(key1 = true) {
        expertDetailsInfoState.value =
            ExpertDetailsApiService().requestExpertDetailsInfo(component._expertId.toString())
    }

    // Handle Expert Details State
    when (val result = expertDetailsInfoState.value) {
        is Resources.Error -> {
            // Handle error state, e.g., show error message
        }

        is Resources.Loading -> {
            HomePageLoadingProgressDialog(
                onDismissRequest = { /* Handle dismiss if necessary */ },
                backgroundColor = Color(0xFF2A2A2A),
                loadingColor = Color.White
            )
        }

        is Resources.Success -> {
            val expertData = result.data?.data?.consultant!!
            ExpertDetailsSuccess(component, expertData = expertData)
        }
    }

    // Handle Submit Review State
    when (val result = submitExpertReviewState) {
        is SubmitExpertReviewState.Error -> {
            // Handle error state, e.g., show error message
            showToast(result.msg)
        }

        is SubmitExpertReviewState.Loading -> {
            if (result.isLoading) {
                HomePageLoadingProgressDialog(
                    onDismissRequest = { /* Handle dismiss if necessary */ },
                    backgroundColor = Color(0xFF2A2A2A),
                    loadingColor = Color.White
                )
            }
        }

        is SubmitExpertReviewState.Success -> {
            LaunchedEffect(key1 = true) {
                // Refresh expert details after successful review submission
                expertDetailsInfoState.value =
                    ExpertDetailsApiService().requestExpertDetailsInfo(component._expertId.toString())
            }
        }
    }
}