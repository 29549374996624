package tta.destinigo.talktoastro.feature_expert_details.expert_review_submit

import androidx.compose.runtime.Composable
import tta.destinigo.talktoastro.shared.local.UserInfoProvider
import tta.destinigo.talktoastro.shared.persentation.LoginDialog

@Composable
fun ExpertReviewSubmitHost(
    onLoginOnClick: () -> Unit,
    onDismiss: () -> Unit,
    onSubmitReview: @Composable () -> Unit
){
    val isUserLogin = UserInfoProvider.getIsLogin()

    if(isUserLogin.not()){
        LoginDialog(
            onDismiss = {onDismiss.invoke()},
            onLoginClicked = { onLoginOnClick.invoke() },
            onSignUpClicked = { onLoginOnClick.invoke() }
        )
    }else{
        onSubmitReview.invoke()
        onDismiss.invoke()
    }
}