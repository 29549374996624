package tta.destinigo.talktoastro.feature_expert_details.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.shared.data.RequestConsultant

@Serializable
data class Consultants(
    @SerialName("current_page") val currentPage: Int, // 1
    @SerialName("data") val data: List<RequestConsultant>,
    @SerialName("first_page_url") val firstPageUrl: String, // http://localhost/winywayapi/public/api/v1/search-consultant?page=1
    @SerialName("from") val from: Int, // 1
    @SerialName("last_page") val lastPage: Int, // 1
    @SerialName("last_page_url") val lastPageUrl: String, // http://localhost/winywayapi/public/api/v1/search-consultant?page=1
    @SerialName("next_page_url")  val nextPageUrl: String?= null, // null
    @SerialName("path") val path: String, // http://localhost/winywayapi/public/api/v1/search-consultant
    @SerialName("per_page") val perPage: Int, // 15
    @SerialName("prev_page_url") val prevPageUrl: String?= null, // null
    @SerialName("to") val to: Int, // 1
    @SerialName("total") val total: Int // 1
)