package tta.destinigo.talktoastro.feature_expert_details.domain


import tta.destinigo.talktoastro.feature_expert_details.data.remote.ExpertDetailsApi
import tta.destinigo.talktoastro.feature_expert_details.data.remote.request.Data
import tta.destinigo.talktoastro.feature_expert_details.data.remote.request.RequestReviewSubmit
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.feature_expert_details.feature_expert_notify.data.request.ExpertAvailableRequest
import tta.destinigo.talktoastro.shared.remote.Resources

class ExpertDetailsRepo(private val api: ExpertDetailsApi) {

    fun getExpertDetails(id:String):Flow<Resources<Data>>{
        return flow {
            emit(Resources.Loading(true))
            val response = api.getExpertDetails(id)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

    fun postExpertReview(request: RequestReviewSubmit):Flow<Resources<Boolean>>{
        return flow {
            emit(Resources.Loading(true))
            val response = api.postSubmitExpertReview(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

    fun notifyWhenExpertAvailable(request: ExpertAvailableRequest):Flow<Resources<Boolean>>{
        return flow {
            emit(Resources.Loading(true))
            val response = api.getNotifyWhenExpertAvailable(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

//    fun getRelatedExpert(request: RelatedExpertRequest):Flow<Resources<Data>>{
//        return flow {
//            emit(Resources.Loading(true))
//            val response = api.getRelatedExpertList(request)
//            emit(response)
//            emit(Resources.Loading(false))
//        }
//    }
}