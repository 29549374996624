package tta.destinigo.talktoastro.feature_expert_details.expert_detail_page.widget

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import kotlin.math.round

@Composable
fun ReviewLayout(reviews: String, experience: String, rating: Double) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 12.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Text(
            text = "Description",
            style = TextStyle(
                fontWeight = FontWeight.Bold,
            ),
//            modifier = Modifier.padding(vertical = 16.dp)
        )
        Row(
            modifier = Modifier.fillMaxWidth().padding(16.dp),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            ReviewItem(label = "Reviews", value = reviews)
            ReviewItem(label = "Experience", value = experience)
            ReviewItem(label = "Ratings", value = rating.toDouble())
        }
    }
}

@Composable
fun ReviewItem(label: String, value: Any) {
    Column(
        modifier = Modifier
            .padding(horizontal = 8.dp), // Adjusted horizontal padding
        horizontalAlignment = Alignment.CenterHorizontally // Added horizontal alignment
    ) {
        Text(text = label)

        val displayValue = if (label.equals("Ratings", ignoreCase = true) && value is Number) {
            val roundedRating = (value.toDouble().coerceIn(0.0, 5.0)?.let { round(it * 10) / 10 } ?: 5.0).toString()
           // Text(text = roundedRating)
            roundedRating.toString()
        } else {
//            Text(text = value.toString())
            value.toString()
        }

        Text(text = displayValue)
    }
}
